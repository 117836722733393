import PropTypes from 'prop-types';
import React from 'react';
import style from './classwise-robots.css';
import {FormattedMessage} from 'react-intl';

class ClasswiseRobots extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            classios: [],
            classioMessagingSystem: undefined
        };

        this.onClassiosArrayChanged = this.onClassiosArrayChanged.bind(this);
    }

    componentDidMount () {
        const {runtime} = this.props;
        this.waitForClassioMessagingSystem(runtime);
    }

    waitForClassioMessagingSystem (runtime) {
        if (runtime.classioMessagingSystem === undefined) {
            setTimeout(() => this.waitForClassioMessagingSystem(runtime), 100);
            return;
        }

        this.setState({classioMessagingSystem: runtime.classioMessagingSystem});

        runtime.classioMessagingSystem.on('robotConnected', this.onClassiosArrayChanged);
        runtime.classioMessagingSystem.on('robotDisconnected', this.onClassiosArrayChanged);
    }

    onClassiosArrayChanged () {
        const {classioMessagingSystem} = this.state;

        const classios = classioMessagingSystem.classios;
        this.setState({classios});
    }

    render () {
        const {classios, classioMessagingSystem} = this.state;

        return (
            <div className={style.ClassioMainContainer}>
                <div className={style.ClassioHeader}>
                    <div>
                        <FormattedMessage
                            id="gui.classwise.robotsList.header"
                            defaultMessage="Missing translation - Connected Classio robots"
                            description="Label for header of the list of connected robots"
                        />
                    </div>
                </div>
                <table className={style.ClassioTable}>
                    {
                        new Array(4).fill(0)
                            .map((_, index) => (
                                <tr key={index}>
                                    <td className={style.ClassioMultiContainerLeft}>
                                        <ClassioElement
                                            number={index * 2 + 1}
                                            classioMessagingSystem={classioMessagingSystem}
                                            classio={classios[index * 2]}
                                        />
                                    </td>
                                    <td className={style.ClassioMultiContainerRight}>
                                        <ClassioElement
                                            number={index * 2 + 2}
                                            classioMessagingSystem={classioMessagingSystem}
                                            classio={classios[index * 2 + 1]}
                                        />
                                    </td>
                                </tr>
                            ))
                    }
                </table>
            </div>

        );
    }
}

ClasswiseRobots.propTypes = {
    runtime: PropTypes.any
};

const ClassioElement = ({number, classio, renderLine, classioMessagingSystem}) => {
    const classioNumber = `C${number}`;
    return (<div className={`${style.ClassioElement} ${renderLine ? style.ClassioElementBottomLine : null}`}>
        <div>{classioNumber}</div>
        <div
            className={`${style.ClassioName} ${classio != null ? style.ClassioColorConnected : style.ClassioColorNotConnected}`}
        >
            {classio != null ? classio.name : '–––'}
        </div>
        <div
            className={style.ClassioSearchIcon}
            onClick={() => sendImHereInstruction(classioMessagingSystem, number)}
        >
            <ClassioSearchIcon connected={classio != null} />
        </div>
    </div>);
};

const sendImHereInstruction = (classioMessagingSystem, classioNumber) => {
    classioMessagingSystem.sendInstructionToRobot({sound: 'im_here', confirmationMode: 0}, classioNumber - 1, true);
};

const ClassioSearchIcon = ({connected}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        fill={connected ? 'hsla(225, 15%, 40%, 1)' : 'hsla(0, 0%, 0%, 0.15)'}
        height="20"
        viewBox="0 0 24 24"
    >
        <path
            d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
        />
        <path
            d="M0 0h24v24H0z"
            fill="none"
        />
    </svg>
);

export default ClasswiseRobots;
